<template>
  <title-wrapper :title="`${$t('components.manageForms.title')} (${count})`">
    <my-data-table
      :data="companies"
      :headers="headers"
      :loading="loading"
      :length="length"
      v-bind:page.sync="params.page"
      :sortBy="sortBy"
      :sortDesc="sortDesc"
      @update:sort-by="sortByFunc"
      @update:sort-desc="sortDescFunc"
    >
      <template v-slot:filters>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="params.companyName"
            hide-details
            :placeholder="$t('helpers.companyName')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="params.email"
            hide-details
            :placeholder="$t('helpers.email')"
            solo
          ></v-text-field
        ></v-col>
        <v-col cols="12" sm="2">
          <v-autocomplete
            solo
            v-model="params.countryCode"
            :items="mappedCountries"
            :hide-details="true"
            :placeholder="$t('helpers.country')"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="params.nip"
            hide-details
            :placeholder="$t('helpers.registerForm')"
            solo
          ></v-text-field
        ></v-col>

        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="onSearch">
            <v-icon>mdi-magnify</v-icon>
          </v-btn></v-col
        >
        <v-col cols="6" sm="1">
          <v-btn color="primary" elevation="0" fab dark block @click="clearParams">
            <v-icon>mdi-cancel</v-icon>
          </v-btn></v-col
        >
      </template>
      <template v-slot:[`item.countryCode`]="{ item }">
        <span>{{ countries.getName(item.countryCode, selectedLanguage) }}</span>
      </template>
      <template v-slot:actions="slotProps">
        <router-link :to="{ name: 'SingleCompany', params: { companyId: slotProps.item._id } }">
          <v-list-item class="hover-item-popup">
            <v-list-item-content> {{ $t('helpers.showDetails') }} </v-list-item-content>
          </v-list-item>
        </router-link>
        <v-list-item
          class="hover-item-popup"
          @click="
            () => {
              activate(slotProps.item.user._id);
            }
          "
        >
          <v-list-item-content>
            {{ $t('components.manageForms.confirmVerification') }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="hover-item-popup"
          @click="
            () => {
              reject(slotProps.item.user._id);
            }
          "
        >
          <v-list-item-content>
            {{ $t('components.manageForms.rejectVerification') }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </my-data-table>
  </title-wrapper>
</template>
<script>
import TitleWrapper from '../../../../components/TitleWrapper.vue';
import MyDataTable from '../../../../components/MyDataTable.vue';
import SysadminService from '../../../../services/SysadminService.js';
import { sortHandler } from '../../../../mixins/sortHandler';
import { countriesCodeHandler } from '../../../../mixins/countriesCodeHandler';
import VueI18n from '../../../../plugins/i18n';

export default {
  name: 'RegistrationForms',
  components: { TitleWrapper, MyDataTable },
  mixins: [sortHandler, countriesCodeHandler],
  data() {
    return {
      count: 0,
      itemsPerPage: 6,
      length: 0,
      companies: [],
      loading: true,
      params: {
        email: '',
        nip: '',
        companyName: '',
        page: 1,
        limit: 6,
        sort: '',
        countryCode: '',
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: VueI18n.t('helpers.fullName'),
          align: 'start',
          value: 'fullName',
          sortable: false,
        },
        { text: VueI18n.t('helpers.companyName'), value: 'companyName' },
        { text: VueI18n.t('helpers.email'), value: 'user.email', sortable: false },
        { text: VueI18n.t('helpers.country'), value: 'countryCode' },
        { text: VueI18n.t('helpers.registerForm'), value: 'nip' },
        { text: '', value: 'actions', sortable: false },
      ];
    },
  },
  watch: {
    'params.page': function () {
      this.getFiltered();
    },
  },
  methods: {
    clearParams() {
      this.params = {
        email: '',
        nip: '',
        companyName: '',
        page: 1,
        limit: 6,
        sort: '',
        countryCode: '',
      };
      this.getFiltered();
    },
    onSearch() {
      this.params.page = 1;
      this.getFiltered();
    },

    async getFiltered() {
      try {
        const response = await SysadminService.getAllPending(this.params);
        this.companies = response.data;
        this.count = response.count;
        this.length = Math.ceil(response.count / this.itemsPerPage);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async activate(id) {
      try {
        await SysadminService.accept(id);
        this.getFiltered();
        this.$toasted.global.success({
          message: VueI18n.t('components.manageForms.toasted.activateFunc'),
        });
      } catch (error) {
        console.error(error);
      }
    },

    async reject(id) {
      try {
        await SysadminService.reject(id);
        this.getFiltered();
        this.$toasted.global.success({
          message: VueI18n.t('components.manageForms.toasted.rejectFunc'),
        });
      } catch (error) {
        console.error(error);
      }
    },
    fetchSort() {
      this.getFiltered();
    },
  },
  async activated() {
    await this.getFiltered();
  },
  async created() {
    await this.getFiltered();
  },
};
</script>
<style lang="scss" scoped>
.v-btn--round {
  border-radius: 4px;
}
</style>
